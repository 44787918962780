export const backendLink  = 'https://admin.englishclassepsom.co.uk'
export const port = '';
export const backendImgLinkGallery = 'https://admin.englishclassepsom.co.uk/backend/';




/*
export const backendLink  = 'https://admin.englishclassepsom.co.uk'
export const port = '';
export const backendImgLinkGallery = 'https://admin.englishclassepsom.co.uk/backend/';

export const backendLink = 'http://localhost'
export const port = ':3000';
export const backendImgLinkGallery = 'http://localhost/backend/';

*/

